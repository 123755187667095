<template>
  <footer class="dark:bg-slate-900 p-4 text-center text-white">
    © 2024 Innovate With V. All rights reserved.
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
}
</script>

<style scoped>
</style>
