<template>
  <div id="About" class="dark:bg-slate-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">Innovate with V</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">Your Partner in Digital Transformation</p>
        <p class="mt-6 text-lg leading-8 dark:text-gray-300">
          At Innovate with V, we specialize in building modern, responsive websites and online stores. Our mission is to help businesses thrive in the digital age with cutting-edge solutions and unparalleled support.
        </p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div v-for="feature in features" :key="feature.name" class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
              <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg  text-gray-900 dark:text-gray-100">
                <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              {{ feature.name }}
            </dt>
            <dd class="mt-2 text-base leading-7 dark:text-gray-300">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DevicePhoneMobileIcon, ShoppingCartIcon, CodeBracketIcon, LifebuoyIcon } from '@heroicons/vue/24/outline'

const features = [
  {
    name: 'Modern Websites',
    description:
      'We create responsive, mobile-friendly websites that provide an optimal user experience on any device.',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'E-commerce Solutions',
    description:
      'Our e-commerce platforms are designed to maximize sales and provide a seamless shopping experience.',
    icon: ShoppingCartIcon,
  },
  {
    name: 'Custom Applications',
    description:
      'From simple tools to complex systems, we build custom applications tailored to your business needs.',
    icon: CodeBracketIcon,
  },
  {
    name: 'Ongoing Support',
    description:
      'Our dedicated support team is here to help you every step of the way, ensuring your success in the digital world.',
    icon: LifebuoyIcon,
  },
]
</script>

<style scoped>
</style>
