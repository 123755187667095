<template>
    <section class="relative isolate overflow-hidden bg-gray-200 px-6 py-24 sm:py-32 lg:px-8">
      <div class="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
      <div class="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-gray-100 shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">What Our Clients Say</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Testimonials</p>
      </div>
      <div class="mx-auto max-w-2xl lg:max-w-4xl mt-16">
        <blockquote class="bg-gray-100 p-8 rounded-lg shadow-lg">
          <div class="flex items-center">
            <img class="h-16 w-16 rounded-full" src="../assets/testimonial.jpg" alt="Client photo" />
            <div class="ml-4">
              <p class="text-lg font-medium text-gray-900">Simon</p>
              <p class="text-sm text-gray-600">Business Owner, Plant Barn</p>
            </div>
          </div>
          <div class="mt-6 text-lg leading-7 text-gray-600">
            "Working with Vishal has been an absolute pleasure. Their expertise in web development and e-commerce solutions has transformed our business. Our new website is fast, responsive, and user-friendly, which has significantly boosted our online presence and sales. The support team is always ready to help and ensures everything runs smoothly. We couldn't be happier with the results!"
          </div>
        </blockquote>
      </div>
    </section>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  </style>
  