<template>
    <div class="dark:bg-slate-900 py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Ready to take the next step?</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">Let's Build Something Great Together</p>
          <p class="mt-6 text-lg leading-8 dark:text-gray-300">Contact us today to discuss your project and how we can help you achieve your goals.</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="#Contact" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Get in Touch</a>
            <a href="#Contact" class="text-sm font-semibold leading-6 text-white">Learn more <span aria-hidden="true">→</span></a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  </style>
  