<template>
  <header :class="['fixed inset-x-0 top-0 z-50 transition-colors duration-300', { 'dark:bg-slate-900 bg-opacity-90 text-white': scrolled, 'bg-transparent text-white': !scrolled }]">
    <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5 hidden lg:inline">
          <span class="sr-only">Innovate With V</span>
          <img :src="scrolled ? '/assets/favcon.png' : '/assets/favcon.png'" :alt="scrolled ? 'Innovate With V - Scrolled' : 'Innovate With V'" class="h-8 w-auto transition-all duration-300" />
        </a>
      </div>
      <div class="flex lg:hidden" v-show="scrolled || mobileMenuOpen">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
          <span class="sr-only">Open Menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm font-semibold leading-6 hover:text-indigo-500">{{ item.name }}</a>
        
        <!-- Dropdown Menu -->
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="text-sm font-semibold leading-6 hover:text-indigo-500 inline-flex items-center">
              Delivered Products
              <ChevronDownIcon class="ml-1 h-4 w-4" aria-hidden="true" />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a href="https://donate.innovatewithv.com" target="_blank" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                    Donation Viewer
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a href="https://bitebackorders.com" target="_blank" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                    BiteBackOrders
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </nav>
    
    <!-- Mobile menu -->
    <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-50" />
      <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Innovate With V</span>
            <img class="h-8 w-auto" src="/assets/favcon.png" alt="Innovate With V" />
          </a>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
              
              <!-- Mobile dropdown header -->
              <div class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900">
                Delivered Products
              </div>
              
              <!-- Mobile dropdown items -->
              <div class="pl-4">
                <a href="https://donate.innovatewithv.com" target="_blank" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Donation Viewer
                </a>
                <a href="https://bitebackorders.com" target="_blank" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  BiteBackOrders
                </a>
              </div>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'About', href: '#About' },
  { name: 'Me', href: '#Team' },
  { name: 'Contact us', href: '#Contact' },
]

const mobileMenuOpen = ref(false)
const scrolled = ref(false)

const handleScroll = () => {
  scrolled.value = window.scrollY > 100
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
</style>